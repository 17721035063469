.transformCenter{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.transformOurstory{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.transformRead{
  position: absolute;
  bottom:50px;
  left:50%;
  transform: translate(-50%,-50%);

}

.transformleft{
  position: absolute;
  
  top:50%;
  transform: translateY(-50%);
}

.transformright{
  position: absolute;
  top:50%;
  transform: translateY(-50%);
}

.transformBottom{
  position: absolute;
  
  left:50%;
  transform: translateX(-50%)
}

.shadow-custom{
box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
}

.customGradient{
  background: linear-gradient(120deg, #051525,#051525, #25426C, #051525, #051525);
}

.partners-custom{
  box-shadow: rgba(99, 99, 99, 0.8) 0px 2px 8px 0px;
}

.custom-shadow-ip {
  box-shadow: 0 4px 4px rgba(255, 255, 255, 0.25);
}

.contact-shadow {
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.6);
}

.contactus-shadow {
  box-shadow: 0 4px 10px rgba(0,0,0, 0.4);
}